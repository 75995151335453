import { ReactNode } from "react"
import { QueryStatus } from "@tanstack/react-query"

import { Banner } from "../../components/Banner"
import { Spinner } from "../../components/Spinner"
import NothingFound from "../../components/NothingFound"
import PartnersContainer from "../../sections/Partners/PartnersContainer"

export interface PartnersLayoutTypes {
  children: ReactNode
  status: QueryStatus | QueryStatus[]
  isLoading: boolean | boolean[]
  isForbidden: boolean
}

const PartnersLayout = ({
  children,
  status,
  isLoading,
  isForbidden,
}: PartnersLayoutTypes) => {
  const statusArr = [...(Array.isArray(status) ? status : [status])]
  const isLoadingArr = [...(Array.isArray(isLoading) ? isLoading : [isLoading])]

  if (isForbidden) {
    return (
      <PartnersContainer>
        <NothingFound
          title="Unauthorized access!"
          text={
            <span>
              You haven't registered as an NCX partner or are not associated
              with any program partners. Please reach out to{" "}
              <a href="mailto:support@ncx.com" className="link">
                support@ncx.com
              </a>{" "}
              for next steps.
            </span>
          }
          linkText="Get support"
          to="mailto:support@ncx.com"
        />
      </PartnersContainer>
    )
  }

  if (isLoadingArr.some(Boolean) || statusArr.includes("pending")) {
    return (
      <PartnersContainer className="items-center justify-center">
        <Spinner />
      </PartnersContainer>
    )
  }

  if (statusArr.includes("error")) {
    return (
      <PartnersContainer>
        <div className="container p-4">
          <Banner
            heading="An error occurred while fetching your information."
            description="Please refresh the page to try again."
          />
        </div>
      </PartnersContainer>
    )
  }

  return (
    <PartnersContainer>
      <div className="container">
        <div className="inner-container max-w-full mx-auto pb-6 md:pb-12 pt-6 md:pt-12">
          {children}
        </div>
      </div>
    </PartnersContainer>
  )
}

export default PartnersLayout
