import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faTriangleExclamation } from "@fortawesome/pro-solid-svg-icons"
import cx from "classnames"

export type VariantType = "error"

export interface BannerProps {
  variant?: VariantType
  heading: string
  description: string
}

const variantIconMap = {
  error: {
    icon: faTriangleExclamation,
    className: "text-red-500",
  },
}

export const Banner = ({
  variant = "error",
  heading,
  description,
}: BannerProps) => {
  const headingClass = cx(
    "text-sm leading-[1.50] tracking-[0.01em] font-medium text-red-800",
    {
      "text-red-800": variant === "error",
    }
  )

  const descriptionClass = cx("mt-1 text-sm leading-[1.50] tracking-[0.01em]", {
    "text-red-700": variant === "error",
  })

  return (
    <div className="rounded-md bg-red-50 p-4">
      <div className="flex">
        <div className="shrink-0 pointer-events-none">
          <FontAwesomeIcon
            icon={variantIconMap[variant].icon}
            className={variantIconMap[variant].className}
            title={faTriangleExclamation.iconName}
          />
        </div>

        <div className="ml-3">
          <h3 className={headingClass}>{heading}</h3>

          {description && <div className={descriptionClass}>{description}</div>}
        </div>
      </div>
    </div>
  )
}
